"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * Chain of line segments for generating spawn positions.
 * @memberof PIXI.particles
 * @class PolygonalChain
 * @constructor
 * @param {{x:number,y:number}[]|{x:number,y:number}[]} data Point data for polygon chains. Either a list of points for a single chain, or a list of chains.
 */
var PolygonalChain = /** @class */ (function () {
    function PolygonalChain(data) {
        this.segments = [];
        this.countingLengths = [];
        this.totalLength = 0;
        this.init(data);
    }
    PolygonalChain.prototype.init = function (data) {
        // if data is not present, set up a segment of length 0
        if (!data || !data.length) {
            this.segments.push({ p1: { x: 0, y: 0 }, p2: { x: 0, y: 0 }, l: 0 });
        }
        else {
            if (Array.isArray(data[0])) {
                // list of segment chains, each defined as a list of points
                for (var i = 0; i < data.length; ++i) {
                    // loop through the chain, connecting points
                    var chain = data[i];
                    var prevPoint = chain[0];
                    for (var j = 1; j < chain.length; ++j) {
                        var second = chain[j];
                        this.segments.push({ p1: prevPoint, p2: second, l: 0 });
                        prevPoint = second;
                    }
                }
            }
            else {
                var prevPoint = data[0];
                // list of points
                for (var i = 1; i < data.length; ++i) {
                    var second = data[i];
                    this.segments.push({ p1: prevPoint, p2: second, l: 0 });
                    prevPoint = second;
                }
            }
        }
        // now go through our segments to calculate the lengths so that we
        // can set up a nice weighted random distribution
        for (var i = 0; i < this.segments.length; ++i) {
            var _a = this.segments[i], p1 = _a.p1, p2 = _a.p2;
            var segLength = Math.sqrt((p2.x - p1.x) * (p2.x - p1.x) + (p2.y - p1.y) * (p2.y - p1.y));
            // save length so we can turn a random number into a 0-1 interpolation value later
            this.segments[i].l = segLength;
            this.totalLength += segLength;
            // keep track of the length so far, counting up
            this.countingLengths.push(this.totalLength);
        }
    };
    /**
     * Gets a random point in the chain.
     * @method getRandomPoint
     * @param {PIXI.Point} out The point to store the selected position in.
     */
    PolygonalChain.prototype.getRandomPoint = function (out) {
        // select a random spot in the length of the chain
        var rand = Math.random() * this.totalLength;
        var chosenSeg;
        var lerp;
        // if only one segment, it wins
        if (this.segments.length === 1) {
            chosenSeg = this.segments[0];
            lerp = rand;
        }
        else {
            // otherwise, go through countingLengths until we have determined
            // which segment we chose
            for (var i = 0; i < this.countingLengths.length; ++i) {
                if (rand < this.countingLengths[i]) {
                    chosenSeg = this.segments[i];
                    // set lerp equal to the length into that segment (i.e. the remainder after subtracting all the segments before it)
                    lerp = i === 0 ? rand : rand - this.countingLengths[i - 1];
                    break;
                }
            }
        }
        // divide lerp by the segment length, to result in a 0-1 number.
        lerp /= chosenSeg.l || 1;
        var p1 = chosenSeg.p1, p2 = chosenSeg.p2;
        // now calculate the position in the segment that the lerp value represents
        out.x = p1.x + lerp * (p2.x - p1.x);
        out.y = p1.y + lerp * (p2.y - p1.y);
    };
    return PolygonalChain;
}());
exports.default = PolygonalChain;
